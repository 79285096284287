<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-card title="充值">
          <b-card-text>选择充值方式</b-card-text>
          <b-card-text class="text-primary">

            <b-form-group
              label="支付方式"
              label-for="pay-methods"
              label-cols-md="4"
            >
              <b-form-select
                v-model="method"
                :options="methods"
              />
            </b-form-group>

            <b-form-group
              label="金额"
              label-for="fee"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="Edit2Icon" />
                </b-input-group-prepend>
                <b-form-input
                  id="alipay-charge"
                  v-model.number="fee"
                  placeholder=""
                />
              </b-input-group>
            </b-form-group>

            <!-- submit and reset -->
            <b-col offset-md="4">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="alipayPc"
              >
                提交充值
              </b-button>

              <p> 若大额无法充值,请尝试一次充值30元,分多次进行充值。</p>

              <p v-if="showAlipayUrl">
                如果没有弹出充值窗口，请新窗口打开 <a :href="alipayUrl">此链接</a>
              </p>
              <p v-if="showQR">
                <span>请扫码支付,支付成功后请刷新页面。</span>
                <qrcode-vue
                  :value="qrCode"
                  :size="size"
                  level="H"
                />
              </p>

            </b-col></b-card-text>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-card title="Code">
          <b-card-text>通过Code充值</b-card-text>
          <b-card-text class="text-danger">
            <b-form-group
              label="充值码"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="EditIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="code"
                  v-model="code"
                  placeholder="输入Code"
                />
              </b-input-group>
            </b-form-group>

            <!-- submit and reset -->
            <b-col offset-md="4">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="codeRedeem"
              >
                兑换Code
              </b-button>

            </b-col>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-card title="充值记录">
          <b-card-text> 余额: {{ me.user.money }} </b-card-text>
          <b-card-text>
            <ChargeOrderList />
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import useHttp from '@/http/useHttp'
import ChargeOrderList from '@/components/pay/ChargeOrderList.vue'
import QrcodeVue from 'qrcode.vue'
import Ripple from 'vue-ripple-directive'
import { notify, throwError, throwRes } from '@/utils/utils'
import {
  BRow, BCol, BCard, BCardText, BFormGroup, BFormInput, BFormSelect, BButton, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BFormSelect,
    ChargeOrderList,
    QrcodeVue,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fee: 30,
      code: '',
      showAlipayUrl: false,
      alipayUrl: '',
      qrCode: '',
      showQR: false,
      size: 256,

      method: 'alipay',
      methods: [
      ],
      me: {
        ext: {},
        user: {},
      },
      orders: [],
    }
  },
  mounted() {
    this.loadme()
  },
  methods: {
    alipayPc() {
      let path = ''
      let argMethod = ''
      let i = 0
      this.methods.forEach((item, index) => {
        // console.log(item.slug, this.method)
        if (item.slug === this.method) {
          path = item.path
          argMethod = item.method
          i = index
        }
      })

      // console.log(this.method, 'pay path', path)

      useHttp.newPay(path, {
        fee: this.fee,
        method: argMethod,
        index: i,
      }).then(res => {
        //  this.notify('OK', '', 'success')
        this.showAlipayUrl = true
        this.alipayUrl = res.data.data.url
        this.showQR = res.data.data.useQrCode
        if (!this.showQR) {
          window.open(res.data.data.url, '_blank')
        } else {
          this.showAlipayUrl = false
          this.qrCode = res.data.data.qrCode
        }
      }).catch(error => {
        throwError(error)
        this.notify('Error', '创建充值订单失败,请尝试其他支付方式.', 'danger')
      })
    },
    codeRedeem() {
      useHttp.redeemCode({
        code: this.code,
      }).then(res => {
        throwRes(res)
        this.notify('OK', '兑换成功', 'info')
      }).catch(error => {
        throwError(error)
        this.notify('Error', 'Code 兑换失败', 'danger')
      })
    },
    loadme() {
      useHttp.getPayMethods().then(res => {
        this.methods = res.data.data.methods
        this.methods.forEach((item, index) => {
          this.methods[index] = {
            slug: item.slug,
            name: item.name,
            path: item.path,
            method: item.method,
            text: item.name,
            value: item.slug,
          }
        })
        this.method = res.data.data.default_method
        // console.log(this.methods)
      }).catch(error => {
        notify('Error', error.toString(), 'danger')
      })
      useHttp.getMe().then(res => {
        this.me = res.data.data
      }).catch(error => {
        notify('Error', error.toString(), 'danger')
      })
    },
    notify,
    throwError,
    throwRes,
  },
}
</script>

<style>

</style>
